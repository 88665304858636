
import Vue from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';
import { mapActions, mapGetters, mapState } from 'vuex';

import { Models, Services } from '@/injectables/tokens';

import AddSlideToLibraryDialog from './AddSlideToLibraryDialog.vue';
import { SlideVisibility } from '@/app/graphql';
import { OutputModelContract, SlideGroupType } from '@/injectables';
import DraggableSlideGroup from '@/features/output/draggable-slide-group.vue';

export default Vue.extend({
  name: 'OutputSidebar',

  components: { AddSlideToLibraryDialog, DraggableSlideGroup },

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Services.Output, Models.Output],

  data: (): {
    previewSlideWidth: number;
    isReordering: boolean;
    slideIdShouldMakeActive: string;
    stagedSlide: OutputSlide | null;
    showAddToLibraryDialog: boolean;
    saveSlideLoading: string[];
  } => ({
    saveSlideLoading: [],
    showAddToLibraryDialog: false,
    stagedSlide: null,
    slideIdShouldMakeActive: '',
    isReordering: false,
    previewSlideWidth: 240,
  }),

  props: {
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    selectedAgency: {
      type: String,
      default: '',
    },
    sidebarWidth: {
      type: Number,
      default: 360,
    },
  },

  watch: {
    slides(newArr: OutputSlide[]): void {
      if (newArr.length && !this.activeSlideId) this.setActiveSlide(newArr[0]?._id || 's-100');
    },
    activeSlideId(): void {
      this.scrollTo();
    },
  },

  computed: {
    ...mapState('agency', { agency: 'currentAgencyInfo' }),
    ...mapGetters('auth', ['isAdmin']),
    drawerWidth(): number {
      return this.show ? this.sidebarWidth : 0;
    },
    activeSlideId: {
      get(): string {
        return this.$store.state.output.activeSlideId;
      },
      set(id: string): void {
        this.$store.dispatch('output/updateActiveSlide', id);
      },
    },
    slidesIsReady(): boolean {
      return this.slides && Array.isArray(this.slides) && this.slides.length > 0;
    },
    slides: {
      get() {
        return this.$store.getters['output/allLocalSlides'].filter(
          slide => slide?.visibility !== SlideVisibility.Deleted,
        );
      },
      set(slidesArr: OutputSlide[]): void {
        const foundSlide =
          this.slideIdShouldMakeActive?.length && slidesArr?.length
            ? slidesArr.find(slide => slide._id === this.slideIdShouldMakeActive)
            : undefined;

        const slides = (this[Models.Output] as OutputModelContract).getMarkedSlides(slidesArr, SlideGroupType.Products);

        this.$store
          .dispatch('output/reorderSlides', { slides, updateLocalSlides: true })
          .then(newSlides => {
            if (foundSlide?.name?.length && newSlides?.length) {
              const foundReorderedSlide = newSlides.find(slide => slide?.name === foundSlide.name);
              if (foundReorderedSlide?._id) {
                this.setActiveSlide(foundReorderedSlide._id);
              }
            }
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('OutputSidebar - set slides/reorder/setactive', err);
          });
      },
    },
    productId(): string {
      return this.$route.params.productId ?? '';
    },
    productSlideStartIndex(): number {
      return this.beforeSlides.length;
    },
    afterProductSlideStartIndex(): number {
      return this.beforeSlides.length + this.productSlides.length;
    },
    slideGroupOptions() {
      return {
        isManageLayouts: this.isManageLayouts,
        isAgencyAdmin: this.isAgencyAdmin,
        isChangeDisabled: this.isChangeDisabled,
        activeSlideId: this.activeSlideId,
      };
    },
  },

  mounted(): void {
    // ensures proper scroll when toggling sidebar from fullscreen
    this.scrollTo();
  },

  methods: {
    ...mapActions('output', { saveOutput: 'saveOutput' }),
    scrollTo(): void {
      const foundSlideDomElement = document.getElementById(`prev_${this.activeSlideId}`);
      const offset = 110;
      const container = this.$el.querySelector('div.v-navigation-drawer__content');

      if (foundSlideDomElement) {
        this.$vuetify.goTo(foundSlideDomElement, {
          offset,
          container,
        });
      }
    },
    closeAddToLibraryDialog(): void {
      this.showAddToLibraryDialog = false;
      this.stagedSlide = null;
    },
    openAddSlideDialog(slide: OutputSlide): void {
      this.stagedSlide = slide;
      this.showAddToLibraryDialog = true;
    },
    onEnd(): void {
      this.isReordering = false;
    },
    setActiveSlide(str: string): void {
      this.activeSlideId = str;
    },
    resetToDefault(slide: OutputSlide) {
      this.$emit('reset-slide', slide);
    },
  },
});
